@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Playfair+Display&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap');
body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.app-container, .mobile-app-container{
  margin:0;
  width:100%;
  height:100%;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  display: flex;
}

.mobile-app-container{
  overflow-x: hidden;
  height:auto;
  flex-direction: column;
  scroll-snap-type: y mandatory;
}

.projects, .services{
  width:100%;
  height:100%;
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  justify-content:space-between;
}

h1,h2,h3,h4,h5,h6,p{
  margin-block: 0;
}

nav{
  width:50px;
  height:50px;
  position: fixed;
  top:25px;
  right:25px;
  background:white;
  display: grid;
  place-content: center;
  border-radius: 100px;
  z-index: 100;

}

nav img{
  width:30px;
  cursor: pointer;
}

.disappearing-layout-menu section{
  height:100%;
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.disappearing-layout-menu section a{
  font-size: 4rem;
  font-family: 'Poppins', serif;
  color: #d3d3d3;

  text-decoration: none;
  font-weight: 800;
  margin: 24px 0;
  border-bottom: 1px solid white;
  width:50vw;
  transition: 0.2s ease-in-out;
}

.disappearing-layout-menu section a:hover{
  color: white;
}

.disappearing-layout-menu section p{
  position: absolute;
  top:50px;
  right:50px;
  color:white;
  font-family: 'Poppins', serif;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.disappearing-layout-menu section p:hover{
  border-bottom: 1px solid white;
}


.landing-banner.footer{
  background: indianred;
  width:50vw;
  height:100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.landing-banner.footer h2{
  color:white;
  font-family: 'Playfair Display', serif;
  font-weight:normal;
  font-size: 1.8rem;
}

.landing-banner.footer h3{
  color:white;
  font-family: 'Poppins', sans-serif;
  font-weight:normal;
  font-size: 1rem;
}

.contact-details{
  display: flex;
  flex-direction: column;
  text-align: center;
}

.contact-details h3{
  margin:15px 0;
}

@media only screen and (max-width:769px) {
  .disappearing-layout-menu section a{
    width:unset;
    font-size:2rem;
    color:white;
  }

  .media-fill-2{
    width:100vw !important;
  }

  .disappearing-layout section{
    flex-direction: column;
    align-items: center !important;
    margin-top:48px;
  }

  .disappearing-layout section p{
    padding:18px 24px;
  }

  .disappearing-layout section h2{
    right:18px !important;
  }

  .disappearing-layout{
    overflow-y:scroll;
  }

  .disappearing-layout section h1{
    font-size: 3rem !important;
  }
}
