.projects-hero-text, .services-hero-text{
    width:50vw;
    height:100vh;
    display:flex;
    flex:none;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.project-hero-img{
    height:100vh;
}

.projects-hero-text h1, .services-hero-text h1{
    font-family: 'Playfair Display', serif;
    font-size:6rem;
    margin-bottom: 48px;
    padding-left: 48px;
    font-weight: normal;
}

.projects-img{
    display: flex;
    align-items: center;
    width:50vw;
}

.projects-img::-webkit-scrollbar{
    display: none;
}

.project-card{
    display:flex;
    flex-direction: column;
    padding:0 24px;
    width:50vw;
    align-items: flex-end;
    justify-content: center
}

.project-card img{
    width:45rem;
    height:35rem;
}

.project-card h2{
    font-family: Poppins, sans-serif;
    font-size: 1.2rem;
    font-style: italic;
    margin-top:24px;
}

.project-details-layout{
    display:flex;
    width:90vw;
    height:100vh;
    justify-content: space-evenly;
    align-items: center;
    color:white;
}

.project-details-layout section, .slider{
    flex:50%;
}

.project-details-layout h1{
    font-size: 3.5rem;
    font-family: 'Poppins', serif;
    max-width:600px;
}

.project-details-layout h2{
    font-size: 1.5rem;
    font-family: 'Playfair Display', sans-serif;
    font-weight: normal;
    opacity:0.4;
    max-width:400px;
    margin-top:48px;
}

.slider{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width:100%;
    height:100%;
    flex-direction: column;
    justify-content: center
}

.slider img{
    width:100%;
}

.project-details-layout h4{
    position: absolute;
    top:20px;
    right:50px;
    color:white;
    font-family: 'Poppins', serif;
    cursor: pointer;
}

.project-details-layout h4:hover{
    border-bottom: 1px solid white;
}

.project-details-layout button{
    background: transparent;
    border:none;
    cursor: pointer;
    padding:0;
    color:white;
    font-family: 'Poppins', serif;
    font-size:1rem;
    transition: 0.2s ease-in-out;
}

.project-details-layout button:hover{
    font-weight:600;
}

.project-details-layout button:disabled{
    color: #b2b2b2;
}

.project-details-layout button:disabled:hover{
    font-weight: normal;
}

