@media only screen and (max-width:769px) {
    .services-hero-img, .services-provided, .project-hero-img{
        width:unset;
        flex-direction: column;
    }
    .projects-hero-text, .services-hero-text{
        width:100vw;
        height: unset;
    }

    .services-hero-text h2, .projects-hero-text h2{
        font-size: 1rem !important;
        padding-left: 12px !important;
    }

    .projects-hero-text h1, .services-hero-text h1, .services-provided.process h1{
        font-size: 2rem;
        padding-left: 12px !important;
    }

    .services-provided.process h1{
        max-width: unset;
        place-content: unset;
    }

    .consultancy-services{
        max-width: unset;
        height: unset;
        align-items: center;
        max-height: unset;
    }

    .consultancy-services h2, .management-services h2{
        font-size: 1.5rem;
        padding-left:12px;
    }

    .table{
        flex-direction: column;
        max-width: 350px;
    }

    section.column{
        margin-right: unset;
    }

    .services-card h3, .services-card p{
        font-size: 1rem;
    }

    .services-provided{
        height: unset;
    }

    .management-services{
        max-width: unset;
    }

    .stages-display{
        padding:48px 12px;
    }
}