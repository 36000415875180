.services-hero-text h2, .projects-hero-text h2{
    font-family: 'Playfair Display', serif;
    padding-left: 48px;
    font-weight: normal;
    font-size: 1.8rem;
    margin-top: 48px;
    max-width: 350px;
    /*opacity: 0.2;*/
    opacity: 0;
}

.services-hero-img, .services-provided, .project-hero-img{
    display: flex;
    align-items: center;
    width:50vw;
    overflow: hidden;
    flex:none;
    justify-content: space-evenly;
}

.services-hero-img img, .project-hero-img img{
    width:100%;
}

.services-provided{
    width:125vw;
    height:100vh;
}

.services-provided.process{
    width:80vw;
    background:#282c34 ;
}

.services-provided.process h1{
    color:white;
    width:100%;
    max-width:50vw;
    font-family: 'Playfair Display', serif;
    place-content: center;
    display:grid;
    font-size: 4.5rem;
    font-weight: normal;
}

.consultancy-services, .management-services{
    display: flex;
    flex-direction: column;
    width:100%;
    max-width: 50vw;
    height:100%;
    justify-content: center;
    flex:none;
    max-height: 80vh;
}

.consultancy-services h2, .management-services h2{
    font-family: 'Playfair Display', serif;
    font-size: 1.8rem;
}

.table{
    display: flex;
    width:100%;
    margin-top: 24px;
}

.management-services{

}

.services-menu{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items:flex-start ;
    width:100%;
}

.stages{
    margin-top: 24px;
    width:100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start
}

.stages h3{
    font-family: 'Poppins', serif;
    font-size:1rem;
    font-weight: bolder;
    cursor: pointer;
    margin:12px 0;
    border-radius: 25px;
}

.stages-display{
    display: grid;
    place-content: center;
    padding:48px 0;
    min-height:250px;
}


.stages-display h3{
    font-family: 'Poppins', serif;
    font-size:1.2rem;
    margin-bottom: 24px;
}
.stages-display p{
    font-family: 'Poppins', serif;
    font-size:1rem;
    font-weight: 300;
    max-width:700px;
    text-align: justify;
}

section.column{
    flex:50%;
    display: flex;
    flex-direction: column;
    margin-right: 24px;
}

.services-card{
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
}

.services-card h3, .services-card p{
    font-family: Poppins,sans-serif;
    margin-bottom:6px;
    font-weight: bolder;
    font-size: 1.2rem;
}

.services-card p{
    font-size: 1rem;
    font-weight: 300;
    max-width: 400px;
}




