@media only screen and (max-width:769px) {
    .projects, .services{
        width:unset;
        height: unset;
        flex-direction: column;
        overflow-x: unset;
        scroll-snap-type: unset;
    }

    .project-hero-img{
        width:unset !important;
        height: unset;
    }

    .projects-hero-text{
        width:100%;
        height: auto;
    }

    .projects-hero-text h1{
        font-size: 2rem;
        padding-left: 12px !important;
    }

    .projects-hero-text h2{
        font-size: 1rem !important;
        padding-left: 12px !important;
    }

    .projects-img{
        width:100vw;
        flex-direction: column;
    }

    .project-card{
        width:unset;
        padding:24px;
    }

    .project-card img{
        max-width:20rem;
        height:unset;
    }

    .project-card h2{
        font-size: 1rem;
        text-align: right;
    }

    .project-details-layout{
        flex-direction: column-reverse;
        justify-content: center;
    }

    .project-details-layout h1{
        font-size: 2rem;
    }

    .project-details-layout h2{
        font-size: 1rem;
        margin-top: 12px;
    }

    .project-details-layout section, .slider{
        flex: none;
    }

    .slider{
        height:unset;
        margin-bottom: 24px;
    }

    .project-details-layout h4{
        right:20px;
    }

}