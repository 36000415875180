.landing-banner{
    background-image: url('media/banner.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display:grid;
    place-content: center;
    height:100vh;
    width:100vw;
    flex: none;
    scroll-snap-type: x mandatory;
}

.landing-banner.about{
    background-image: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.landing-banner.about.empanelment{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width:50vw;
    padding:0 0 0 32px;
    max-width: unset;
}

.landing-banner.about h2{
    font-family: 'Playfair Display', serif;
    font-weight: normal;
    font-size: 1.8rem;
}

.landing-banner.about section{
    text-align: left;
}

.about-us{
    max-width: 50vw;
    width:100%;
    display: grid;
    justify-content: center;
    padding: 0 32px;
}

.special-interests, .vision, .teams-section, .empanelment{
    max-width:40vw;
    width:100%;
    height:100vh;
    background: #282c34;
    padding: 0 32px;
    display: grid;
    align-items: center;
    place-content: center;
}

.vision, .core-team, .teams-section, .advisor-section{
    max-width: 50vw;
}

.teams-section, .other{
    background: white;
}

.landing-banner.about.other{
    width: 150vw;
    justify-content: space-evenly;
}

.landing-banner.about.empanelment h2{
    color: white;
}

.consultants, .associations, .clients, .landing-banner.about.empanelment .clients p {
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-style: normal;
}

.client-section, .empanelment-section{
    margin:36px 0;
}

.associations, .clients{
    justify-content: flex-start;
}

.consultants section text, .consultants section, .associations section, .clients section{
    display: flex;
    flex-direction: column;
    font-weight: 300;
}

.associations section, .clients section{
    color: white;
    flex:33%;
}

.consultants section text b{
    font-size: 0.8rem;
    font-weight: 800;
    margin-bottom: 15px;
}

.consultants section{
    margin-right:32px;
    width: 20vw;
}

.core-team{
    display: flex;
    justify-content: space-evenly;
    width:100%;
    padding:0 32px;
}

.core-team img{
    width: 25rem;
    margin-right:15px;
}

.core-team h3{
    font-family: 'Playfair Display', serif;
}

.advisors, .advisor-section section{
    display: flex;
    justify-content: space-between;
}

.advisor-section, .advisor-section section{
    flex-direction: column;
    justify-content: space-between;
}

.empanelment-section{
    display: flex;
    flex-direction: column;
}

.empanelment-section{
    width: 100%;
}

.associations section text, .clients section text{
    margin: 9px 0;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    color: white;
}

.advisors section text{
    margin: 9px 0;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-weight: 300;
}

.landing-banner.about .consultant-section h1{
    font-size: 3rem;
}

.landing-banner.about .special-interests h1{
    color: white;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    font-size: 4.2rem;
}

.landing-banner.about .about-us p, .landing-banner.about .vision p, .landing-banner.about .teams-section p{
    font-size: 1rem;
    font-style: normal;
    max-width: 30vw;
}

.landing-banner.about .vision p, .landing-banner.about .vision h2{
    color: white;
}

.landing-banner h1, .landing-banner p, .landing-banner a, .landing-banner h2{
    color:black;
}

.landing-banner section{
    max-width:800px;
    text-align: center;
}

.landing-banner h1{
    font-family: 'Playfair Display', serif;
    font-weight: normal;
    font-size: 5rem;
}

.landing-banner h3{
    font-family: 'Playfair Display', serif;
    margin-bottom: 24px;
}

.landing-banner p {
    font-family: Poppins, sans-serif;
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 300;
}

.media-fill, .media-fill-2, .media-fill-3{
    background-image: url('media/filler.jpg');
    background-attachment: fixed;
    background-position: top center;
}

.media-fill-2{
    background-image: url('media/filler_2.jpg');
    width: 50vw;
}

.media-fill-3{
    background-image: url('media/filler_3.jpg');
    width: 50vw;
}

.disappearing-layout, .disappearing-layout-menu, .disappearing-layout-project{
    background: indianred;
    transform: translateY(100vh);
    height: 100vh;
    width:100vw;
    position: fixed;
    top:0;
    left:0;
    display: grid;
    place-items: center;
    z-index: 102;
}

.disappearing-layout section{
    display: flex;
    justify-content: space-evenly;
    width:100%;
    align-items: flex-end;
    position: relative;
}

.disappearing-layout section p, .disappearing-layout section h1, .disappearing-layout section h2{
    color:white;
    font-family: Poppins, sans-serif;
}

.disappearing-layout section p{
    font-size: 1rem;
    font-weight: 300;
    max-width: 800px;
}

.disappearing-layout section h1{
    font-size: 4.2rem;
}

.disappearing-layout section h2{
    font-size: 1rem;
    font-weight: 600;
    font-style: italic;
    position: absolute;
    top:0; right:64px;
    cursor: pointer;
}

@keyframes slideIn {
    0%{
        transform: translateY(100vh);
    }

    100%{
        transform: translateY(0);
    }
}

@keyframes slideOut {
    0%{
        transform: translateY(0);
    }

    100%{
        transform: translateY(-100vh);
    }
}