@media only screen and (max-width:769px) {
    body{
        overflow-x: hidden;
        overflow-y:scroll;
        height:auto;
        width:100%;
    }

    nav{
        bottom:10px;
        left:10px   ;
        top: unset;
        height:50px;
    }

    .about-us{
        max-width: 600px;
        margin: 24px 0;
        padding:0;
    }

    .landing-banner h1{
        font-size: 3rem;
    }

    .landing-banner.about .special-interests h1{
        font-size: 2.5rem;
        text-align: center;
        padding-top:24px;
    }

    .landing-banner.about.other{
        width:100vw;
        margin-bottom: 32px;
    }

    .landing-banner.about.empanelment{
        flex-direction: column;
        width:100vw;
        padding:0;
    }

    .landing-banner p{
        font-size: 1rem;
    }

    .landing-banner.about h2{
        font-size: 1.5rem;
        text-align: center;
    }

    .landing-banner section{
        max-width: 300px;
    }

    .landing-banner.about section{
        text-align: center;
    }

    .landing-banner.about{
        flex-direction: column;
    }

    .landing-banner.about .about-us p, .landing-banner.about .vision p, .landing-banner.about .teams-section p{
        max-width: 600px;
        padding:0 24px;
    }

    .special-interests, .vision, .teams-section, .empanelment{
        max-width: unset;
    }

    .special-interests, .vision, .teams-section, .empanelment{
        padding:24px 0;
        height:auto;
    }

    .vision, .core-team, .teams-section, .advisor-section{
        max-width:unset;
    }

    .landing-banner.about{
        height:unset;
    }

    .media-fill-3{
        width:100%;
    }

    .landing-banner.footer{
        width:100%;
    }

    .core-team{
        flex-direction: column;
        margin: 24px 0;
        padding:0;
        align-items: center;
    }
    .core-team img{
        width:15rem;
    }
    .core-team h3{
        margin: 12px 0 !important;
    }

    .consultant-section{
        width:100%;
    }

    .consultants, .associations, .clients, .landing-banner.about.empanelment .clients p{
        flex-direction: column;
        align-items:center;
    }

    .consultants section{
        width: unset;
        margin-right: unset;
    }

    .associations section, .clients section{
        width: unset;
    }

    .client-section, .empanelment-section{
        margin:24px 0;
    }

    .client-section{
        width:100%;
    }


}

@media only screen and (min-width:680px){

}